.background{
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
}

.spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 9999;
}
