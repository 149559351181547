@font-face {
    font-style: normal;
    font-family: 'NotoSansKR-Regular';
    src: url('./NotoSansKR-Regular.otf');
}

@font-face {
    font-style: normal;
    font-family: 'NotoSansKR-Medium';
    src: url('./NotoSansKR-Medium.otf');
}

@font-face {
    font-style: normal;
    font-family: 'NotoSansKR-Bold';
    src: url('./NotoSansKR-Bold.otf');
}

@font-face {
    font-style: normal;
    font-family: 'Roboto-Medium';
    src: url('./Roboto-Medium.ttf');
}

@font-face {
    font-style: normal;
    font-family: 'Roboto-Bold';
    src: url('./Roboto-Bold.ttf');
}