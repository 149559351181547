body {
    font-family: "NotoSansKR-Regular";
    font-size: 15px;
    color: #222;
    line-height: 21px;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

}

body::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari, Opera*/
}

a {
    cursor: pointer;
}

p {
    font-family: "NotoSansKR-Regular";
    font-size: 15px;
    color: #222;
    line-height: 21px;
    margin: 0;
}

:root {
    --colorGreen: #006234;
    --colorWhite: #fff;
    --colorBlack: #222;
    background-color: #f3f3f3;
}

#root {
    background-color: #f3f3f3;
}

button {
    white-space: nowrap;
}


input[type="checkbox"] {
    cursor: pointer;
}

input[type="radio"] {
    cursor: pointer;
}

.f-regular {
    font-family: "NotoSansKR-Regular" !important;
}

.f-bold {
    font-family: "NotoSansKR-Bold" !important;
}

.f-medium {
    font-family: "NotoSansKR-Medium" !important;
}

.f-roboto-medium {
    font-family: "Roboto-Medium" !important;
}

.f-roboto-bold {
    font-family: "Roboto-Bold" !important;
}

.f-15 {
    color: #222;
    font-size: 15px;
    line-height: 21px;
}

.f-12 {
    font-size: 12px;
    line-height: 21px;
}

.f-20 {
    font-size: 20px !important;
    line-height: 29px !important;
}

.ms-8px {
    margin-left: 8px !important;
}

.ms-12 {
    margin-left: 12px;
}

.me-15px {
    margin-right: 15px !important;
}

.me-20px {
    margin-right: 20px !important;
}

.ms-3px {
    margin-left: 3px !important;
}

.me-3px {
    margin-right: 3px !important;
}

.me-10px {
    margin-right: 10px !important;
}

.mx-3px {
    margin: 0 3px !important;
}

.ms-12px {
    margin-left: 12px !important;
}

.mx-8px {
    margin-left: 8px !important;
    margin-right: 8px !important;
}

.mr-8 {
    margin-right: 8px;
}

.mh-107px {
    min-height: 107px;
}

.h-25px {
    height: 25px !important;
}

.h-20px {
    height: 20px !important;
}

.h-618px {
    height: 618px !important;
    overflow-y: auto;
}

.h-750px {
    max-height: 750px !important;
    overflow-y: auto;
}

.h-515px {
    max-height: 515px !important;
    overflow-y: auto;
}

.h-553px {
    height: 553px !important;
    overflow-y: auto;
}

.h-493px {
    height: 493px !important;
    overflow-y: auto;
}

.w-6 {
    width: 6% !important;
}

.w-49 {
    width: 49% !important;
}

.w-100px {
    width: 100px !important;
}

.w-125px {
    width: 125px !important;
}

.w-135px {
    width: 135px !important;
}

.w-150px {
    width: 150px !important;
}

.w-250px {
    width: 250px !important;
}


.h-50 {
    height: 5000px !important;
}

.h-103 {
    max-height: 103px !important;
    overflow-y: auto;
}

.h-248 {
    max-height: 248px !important;
    overflow-y: auto;
}

.h-153 {
    max-height: 153px !important;
    overflow-y: auto;
}

.h-183 {
    /*height: 183px !important;*/
    max-height: 183px !important;
    overflow-y: auto;
}

.h-196 {
    max-height: 196px !important;
    overflow-y: auto;
}

.h-225 {
    max-height: 230px !important;
    overflow-y: auto;
}

.h-333 {
    /*height: 333px !important;*/
    max-height: 333px !important;
    overflow-y: auto;
}

.h-350 {
    /*height: 333px !important;*/
    max-height: 350px !important;
    overflow-y: auto;
}


.h-633 {
    /*height: 663px !important;*/
    max-height: 633px !important;
    overflow-y: auto;
}

.h-663 {
    /*height: 663px !important;*/
    max-height: 663px !important;
    overflow-y: auto;
}

.h-650 {
    /*height: 663px !important;*/
    max-height: 650px !important;
    overflow-y: auto;
}

.h-243 {
    max-height: 243px !important;
    overflow-y: auto;
}

.h-443 {
    max-height: 443px !important;
    overflow-y: auto;
}

.h-843 {
    max-height: 843px !important;
    overflow-y: auto;
}

.h-228 {
    max-height: 228px !important;
    overflow-y: auto;
}

.h-252 {
    max-height: 252px !important;
    overflow-y: auto;
}

.h-278 {
    max-height: 278px !important;
    overflow-y: auto;
}

.h-478 {
    height: 478px !important;
    overflow-y: auto;
}

.h-528 {
    max-height: 528px !important;
    overflow-y: auto;
}

.h-31 {
    height: 31px !important;
}

.h-30 {
    height: 30px !important;
}

.pb-10px {
    padding-bottom: 10px !important;
}

.mt-10px {
    margin-top: 10px !important;
}

.mt-15px {
    margin-top: 15px !important;
}

.mt-14px {
    margin-top: 14px !important;
}

.mb-10px {
    margin-bottom: 10px !important;
}

.mb-8px {
    margin-bottom: 8px !important;
}

.mb-15px {
    margin-bottom: 15px !important;
}

.my-10px {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.my-15px {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
}

.py-15px {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.mb-40 {
    margin-bottom: 40px !important;
}

.mt-20 {
    margin-top: 20px !important;
}

.mt-10 {
    margin-top: 10px !important;
}

.mt-5px {
    margin-top: 5px !important;
}

.w-65 {
    width: 65% !important;
}

.w-80 {
    width: 80% !important;
}

.w-83 {
    width: 83% !important;
}

.w-90 {
    width: 90% !important;
}

.w-95 {
    width: 95% !important;
}

.w-94 {
    width: 94% !important;
}

.w-33 {
    width: 33% !important;
}

.w-32 {
    width: 32% !important;
}

@page {
    size: A4;
    margin: 20mm;
}

.w-29 {
    width: 29% !important;
}

.w-30 {
    width: 30% !important;
}

.w-19 {
    width: 19% !important;
}

.w-20 {
    width: 20% !important;
}

.w-25 {
    width: 25% !important;
}

.w-75 {
    width: 75% !important;
}

.w-15 {
    width: 15% !important;
}

.w-35 {
    width: 35% !important;
}

.w-40 {
    width: 40% !important;
}

.w-49 {
    width: 49% !important;
}

.w-70 {
    width: 70% !important;
}

.w-70px {
    width: 70px !important;
}

.w-60 {
    width: 60% !important;
}

.w-52 {
    width: 52% !important;
}

.w-54 {
    width: 54% !important;
}

.w-55 {
    width: 55% !important;
}

.w-45 {
    width: 45% !important;
}

.w-200,
.w-200px {
    width: 200px !important;
}

.w-77 {
    width: 77% !important;
}

.w-120px {
    width: 120px !important;
}

.w-110px {
    width: 110px !important;
}

.page-wrapper {
    display: flex;
    /*width:100%;*/
    /*overflow:hidden;*/
}

.btn_group_top {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.btn_group_top>.btn.btn-outline-primary,
.btn_group_top>.btn.btn-primary {
    margin-left: 8px;
}

.g-3,
.gx-3 {
    --bs-gutter-x: 0.9rem !important;
}

.smsStepText {
    color: #000;
    font-size: 15px;
    line-height: 21px;
    font-family: "NotoSansKR-Regular";
    margin: 0 7px;
}

.smsModal .modal-dialog {
    max-width: unset;
    width: 1150px;
}

.smsOuter {
    width: 100%;
    min-height: 200px;
    border: 1px solid #c5c5c5;
}

.smsOuter.footer {
    min-height: unset;
    height: fit-content;
}

.smsTopSection {
    width: 100%;
    height: 40px;
    border-bottom: 1px solid #c5c5c5;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 10px;
}

.smsBtmRow {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 10px;
}

.smsBluebox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 10px;
    border: 1px solid blue;
    background-color: lightblue;
    color: #000;
    font-size: 15px;
    line-height: 21px;
    font-family: "NotoSansKR-Regular";
    min-width: 100px;
    margin: 0 5px;
}

.smsGreenbox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 10px;
    border: 1px solid green;
    background-color: lightgreen;
    color: #000;
    font-size: 15px;
    line-height: 21px;
    font-family: "NotoSansKR-Regular";
    min-width: 100px;
    margin: 0 5px;
}

.smsRedbox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 10px;
    border: 1px solid red;
    color: red;
    font-size: 15px;
    line-height: 21px;
    font-family: "NotoSansKR-Regular";
    min-width: 100px;
    margin: 0 5px;
}

.smsContent {
    padding: 10px;
}

.h-unset {
    height: unset;
}

.smsGreybox {
    border: 1px solid #c5c5c5;
    color: #000;
    font-size: 15px;
    line-height: 21px;
    font-family: "NotoSansKR-Regular";
}

.table.table-fit td,
.table.table-fit th {
    white-space: nowrap;
    width: 1%;
    padding: 0 10px !important;
}

.tabs-side-content {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    right: 20px;
}

.btn-refresh {
    display: inline-block;
    width: 25px;
    height: 25px;
    background: url(../../public/images/btn-refresh.png) no-repeat center;
}

.style-none {
    list-style: none !important;
    padding-left: 0 !important;
}

.btn-tabs-right {
    margin-bottom: -25px;
}

.modal-scroll.w-1200px .modal-content {
    max-width: 1200px !important;
}

.ps-20px {
    padding-left: 20px !important;
}

.pe-20px {
    padding-right: 20px !important;
}

.table.my-table1 tbody tr th.nobg {
    background-color: unset;
}

.borderless td,
.borderless th {
    border: none;
}

@media screen and (max-width: 1900px) {
    .page-wrapper {
        /*overflow:scroll;*/
    }
}

.cursor-pointer {
    cursor: pointer;
}

.blue-tr td {
    background-color: rgb(204, 229, 255) !important;
}

.yellow-tr td {
    background-color: rgb(255, 255, 204) !important;
}

.grey-tr td {
    background-color: rgb(230, 230, 230) !important;
}

.darkgrey-tr td {
    background-color: rgb(215, 215, 215) !important;
}

.lightred-tr td {
    background-color: rgb(255, 240, 245) !important;
}

.orange-tr td {
    background-color: rgb(255, 229, 204) !important;
}

.green-tr td {
    background-color: rgb(229, 255, 204) !important;
}

input[type="date"] {
    border-radius: 0;
    color: #222;
    font-size: 15px;
    line-height: 21px;
    font-family: "NotoSansKR-Medium";
}

.header-outer {
    width: 100%;
    min-width: 1230px;
    height: 64px;
    background-color: #fff;
    position: fixed;
    z-index: 10;
    border-bottom: 1px solid #c8c3c3;
}

.logo-wrapper {
    /* background-color: lightyellow; */
    width: 241px;
    height: 64px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    float: left;
    gap: 15px;
}

.login-content-outer {
    width: 100vw;
    height: 100vh;
    background-image: url("../../public/images/login_bg.png");
    /*background-size: contain;*/
    /*background-repeat: no-repeat;*/
    position: relative;
}

.login-content-inner {
    position: absolute;
    width: 500px;
    padding: 65px 50px;
    background-color: #f6f6f6;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-content-inner::before {
    content: "";
    width: 145px;
    height: 30px;
    background-image: url("../../public/images/donga-logo.png");
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: -60px;
}

.login-content-title {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
}

.login-content-title>span:first-child {
    color: #0b4da2;
    font-size: 18px;
    font-family: NotoSansKR-Medium;
    margin-bottom: 10px;
}

.login-content-title>span:last-child {
    color: #8a7a7a;
    font-size: 14px;
    font-family: NotoSansKR-Regular;
}

.login-content-input {
    width: 100%;
}

.login-content-input>div:first-child {
    margin-bottom: 30px;
}

.login-content-input input {
    width: 100%;
    height: 34px;
    border: none;
    border-bottom: 1px solid #8a7a7a;
    background-color: #f6f6f6;
}

.login-content-input input.login-active {
    border-bottom: 1px solid #ec2020;
}

.login-content-input input:focus {
    outline: none;
}

.login-content-input input::placeholder {
    color: #8a7a7a;
    font-size: 14px;
}

.login-content-validation {
    width: 100%;
    color: #ec2020;
    font-size: 12px;
    font-family: NotoSansKR-Medium;
    margin-top: 9px;
}

.login-content-button {
    width: 100%;
    margin-top: 30px;
}

.login-content-button button {
    width: 100%;
    height: 48px;
    background-color: #0b4da2;
    color: #ffffff;
    font-family: NotoSansKR-Regular;
    border: none;
    border-radius: 3px;
}

.login-content-footer {
    width: 100%;
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 12px;
    font-family: NotoSansKR-Regular;
}

.login-content-footer>span {
    cursor: pointer;
}

.login-content-footer>div {
    margin: 0 8px;
}

.mainmenu-wrapper {
    width: calc(100% - 241px);
    height: 64px;
    /* background-color: lightblue; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
}

.mainmenu-userinfo {
    margin-right: 20px;
    display: flex;
    align-items: center;
}

.mainmenu-userinfo>img {
    width: 24px;
    height: 24px;
}

.mainmenu-userinfo>div:nth-of-type(1) {
    font-size: 20px;
    font-family: "NotoSansKR-Bold";
    margin-right: 12px;
}

.mainmenu-userinfo>div>div:nth-of-type(2) {
    font-size: 15px;
    font-family: "NotoSansKR-Medium";
    cursor: pointer;
}

.mainmenu-userinfo>div:nth-of-type(2):hover {
    color: #0b4da2;
}

.mainmenu-userinfo a,
.mainmenu-userinfo a:hover,
.mainmenu-userinfo a:focus,
.mainmenu-userinfo a:active,
.mainmenu-userinfo a:visited {
    color: #707070;
    font-size: 20px;
    line-height: 29px;
    font-family: "NotoSansKR-Bold";
    text-decoration: none;
    width: fit-content;
}

/*.mainmenu-userinfo img{*/
/*    width:24px;*/
/*    height: 24px;*/
/*    margin-right: 5px;*/
/*}*/

.mainmenu {
    list-style: none;
    margin: 0;
    padding: 0;
}

.mainmenu li {
    display: inline-block;
    width: fit-content;
    text-align: center;
}

.mainmenu li:first-child {
    margin: 0 20px;
}

.mainmenu li:nth-child(2) {
    margin: 0 95px 0 75px;
}

.mainmenu li:nth-child(3) {
    margin: 0 95px 0 0;
}

.mainmenu li:nth-child(4) {
    margin: 0 95px 0 0;
}

.mainmenu li:nth-child(5) {
    margin: 0 95px 0 0;
}

.mainmenu li a,
.mainmenu li a:active,
.mainmenu li a:focus,
.mainmenu li a:visited {
    text-decoration: none;
    color: #707070;
    font-size: 20px;
    line-height: 29px;
    font-family: "NotoSansKR-Bold";
}

.mainmenu li a:hover,
.mainmenu li.active a,
.mainmenu li.active a:hover {
    color: #0b4da2;
}

.sidebar-wrapper {
    width: 241px;
    min-width: 241px;
    /*min-height: 1080px;*/
    background-color: #fff;
    z-index: 10;
    border-right: 1px solid #c8c3c3;
    position: fixed;
    top: 64px;
    bottom: 0;
    overflow: auto;
}

.sidebar-wrapper>ul {
    list-style: none;
    padding: 0;
    margin: 18px 0 0;
}

.sidebar-wrapper>ul>li {
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
    justify-content: space-between;
    margin-bottom: 16px;
    padding: 0 18px;
    cursor: pointer;
    white-space: nowrap;
}

.sidebar-wrapper>ul>li>div {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sidebar-wrapper>ul>li:hover>div>span,
.sidebar-wrapper>ul>li.active>div>span {
    transform: rotate(270deg);
}

.sidebar-wrapper>ul>li,
.sidebar-wrapper>ul>li:focus,
.sidebar-wrapper>ul>li:active,
.sidebar-wrapper>ul>li span {
    display: block;
    text-decoration: none;
    color: #222;
    font-size: 15px;
    line-height: 21px;
    font-family: "NotoSansKR-Bold";
}

.sidebar-wrapper>ul>li span {
    display: block;
    width: 9px;
    height: 5px;
    background: url(../../public/images/icon-menu-expand.svg) center no-repeat;
    background-size: cover;
}

.sidebar-wrapper>ul li:hover,
.sidebar-wrapper>ul li:hover.active,
.sidebar-wrapper>ul li.active {
    color: #0b4da2;
}

.sidebar-wrapper>ul>li ul {
    display: none;
}

.sidebar-wrapper>ul>li:hover ul,
.sidebar-wrapper>ul>li.active ul {
    display: block;
}

.sidebar-wrapper>ul>li ul li {
    margin: 10px 0;
}

.sidebar-wrapper>ul>li ul li:last-child {
    margin: 0;
}

.sidebar_subList>li {
    color: #222222;
    cursor: pointer;
}

.sidebar_subList>li:hover,
.sidebar_subList>li:hover.active,
.sidebar_subList>li.active {
    color: #0b4da2;
}

.content-wrapper {
    /*min-height: 1080px;*/
    width: 100%;
    background-color: #f3f3f3;
}

.content-outer {
    padding: 64px 0 0 0;
    /*width:100%;*/
    /*min-width: 1640px;*/
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    /*max-width:calc(100% - 260px);*/
    /*margin-left: 241px;*/
}

.content-outer.active {
    margin-left: 241px;
}

/*.wrapper_points{*/
/*    width:calc(100% - 260px);*/
/*    min-width: 1660px;*/
/*    min-height: 80px;*/
/*    height:fit-content;*/
/*    display:flex;*/
/*    align-items:center;*/
/*    justify-content: flex-start;*/
/*    background-color: #fff;*/
/*    padding: 20px 20px 20px 20px;*/
/*    margin: 0 0 30px 0;*/
/*    position: fixed;*/
/*    z-index:99;*/
/*    flex-wrap: wrap;*/
/*}*/
/*.box_points{*/
/*    min-width: 180px;*/
/*    width:fit-content;*/
/*    height: 30px;*/
/*    border-radius: 4px;*/
/*    border:1px solid #707070;*/
/*    background-color:#fff;*/
/*    color: #222;*/
/*    font-size:15px;*/
/*    line-height: 21px;*/
/*    font-family:"NotoSansKR-Bold";*/
/*    display:flex;*/
/*    align-items: center;*/
/*    justify-content: space-between;*/
/*    padding: 0 0 0 10px;*/
/*    margin-right:5px;*/
/*    margin-bottom: 10px;*/
/*}*/
/*.box_points .box_points_close{*/
/*    width:30px;*/
/*    height:30px;*/
/*    background-image: url(../../public/images/tab-close.png);*/
/*    background-repeat: no-repeat;*/
/*    background-size: auto;*/
/*    background-position: center;*/
/*}*/
/*.box_points.active{*/
/*    border-color:#0B4DA2;*/
/*    background-color:#0B4DA2;*/
/*    color: #fff;*/
/*}*/
/*.box_points.active .box_points_close{*/
/*    background-image: url(../../public/images/tab-close-active.png);*/
/*    background-repeat: no-repeat;*/
/*    background-size: auto;*/
/*}*/
/*.box_points a{*/
/*    display: inline-block;*/
/*    width:calc(100% - 30px);*/
/*}*/
.wrapper_points {
    position: fixed;
    width: 100%;
    height: 40px;
    background-color: #fff;
    padding: 0 20px;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.box_points {
    min-width: 192px;
    width: 192px;
    max-width: 192px;
    height: 30px;
    border-radius: 4px;
    border: 1px solid #c5c5c5;
    background-color: #fff;
    color: #222;
    font-size: 15px;
    font-family: "NotoSansKR-Bold";
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 5px;
    padding-left: 10px;
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.box_points:hover {
    color: #0b4da2;
}

.box_points .box_points_close {
    width: 30px;
    min-width: 30px;
    height: 30px;
    background-image: url(../../public/images/tab-close.png);
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
    z-index: 1000;
}

.box_points.active {
    border-color: #0b4da2;
    background-color: #0b4da2;
    color: #fff;
}

.box_points.active .box_points_close {
    background-image: url(../../public/images/tab-close-active.png);
    background-repeat: no-repeat;
    background-size: auto;
    z-index: 1000;
}

.box_points a {
    display: inline-block;
    width: calc(100% - 30px);
}

.content-inner {
    width: 100%;
    min-width: 1640px;
    /* margin-top:90px; */
    /*padding:50px 20px 0 20px;*/
    /*background-color: #F3F3F3;*/
    /*position:relative;*/
    padding: 0 20px 0 20px;
    background-color: #f3f3f3;
    position: relative;
    overflow-y: auto;
}

.modal-dialog.modal-xl .modal-content .modal-body {
    padding: 20px;
}

.modal-body .content-inner {
    background-color: transparent;
    padding: 0;
    position: relative;
    overflow: unset;
}

.modal-body .content-inner .filter_fields_container {
    border: 1px solid #C8C3C3;
    border-radius: 0;
    padding: 16px;
    margin-bottom: 9px;
    position: relative;
    background-color: transparent;
}

.modal-body .content-inner .filter_fields_container .filter1-field1 {
    padding-left: 0;
}

.filter1_container {
    /*margin:5px 0;*/
    /*min-width: 1280px;*/
}

.filter_header_row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 7px;
}

.filter-title {
    color: #222;
    font-size: 20px;
    /*line-height: 20px;*/
    font-family: "NotoSansKR-Bold";
    /*margin-top:10px;*/
}

.filter-caption {
    color: #222;
    font-size: 15px;
    line-height: 21px;
    font-family: "NotoSansKR-Regular";
}

.filter-buttons {
    display: flex;
    align-items: center;
}

.btn-outline-dark.my-btn-outline-dark,
.btn-outline-dark.my-btn-outline-dark:hover,
.btn-outline-dark.my-btn-outline-dark:focus,
.btn-outline-dark.my-btn-outline-dark:active {
    width: fit-content;
    height: 25px;
    color: #222 !important;
    font-size: 14px;
    line-height: 21px;
    font-family: "NotoSansKR-Medium";
    border-radius: 3px;
    padding: 0 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff !important;
}

.btn.btn-outline-secondary,
.btn.btn-outline-secondary:hover,
.btn.btn-outline-secondary:focus,
.btn.btn-outline-secondary:active {
    width: fit-content;
    min-width: 70px;
    height: 25px;
    color: #222 !important;
    border-color: #777;
    font-size: 14px;
    line-height: 21px;
    font-family: "NotoSansKR-Medium";
    border-radius: 3px;
    padding: 0 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff !important;
}

.filter-buttons button.btn-outline-dark.my-btn-outline-dark {
    margin-right: 8px;
    min-width: 150px;
    padding: 0 10px;
}

.filter-buttons button:last-child {
    margin-right: 0 !important;
}

.filter_fields_container {
    width: 100%;
    /*min-height:51px;*/
    height: fit-content;
    background: #fff;
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    flex-direction: column;
    padding: 10px 0;
    border-radius: 9px;
}

.filter_fields_row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.modal_float_title {
    color: #000;
    font-size: 12px;
    line-height: 17px;
    font-family: "NotoSansKR-Medium";
    margin: 0;
    padding: 0;
    position: absolute;
    top: -10px;
    left: 13px;
    background-color: #fff;
    padding: 0 5px;
    z-index: 9;
}

.filter1-field {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.filter1-field1 {
    padding-left: 20px;
}

.filter1-field2 {
    padding-left: 40px;
}

.filter1-field3 {
    padding-left: 90px;
}

.filter1-field4 {
    padding-left: 31px;
}

.filter1-field5 {
    padding-left: 32px;
}

.filter-field1-title {
    width: 100%;
    padding-bottom: 10px;
    font-family: NotoSansKR-Bold;
}

input.form-check-input[type="radio"]:focus {
    box-shadow: unset;
}

input.form-check-input[type="checkbox"] {
    width: 15px;
    height: 15px;
    margin-right: 5px;
    border-radius: unset;
    border-color: #707070;
    box-shadow: none;
}

.filter1-field .form-check-input[type="checkbox"] {
    width: 15px;
    height: 15px;
    margin-right: 5px;
    border-radius: unset;
    border-color: #707070;
    box-shadow: none;
}

.filter1-field4 .form-check-input[type="checkbox"] {
    margin-right: 8px;
}

.filter1-field5 .form-check-input[type="checkbox"] {
    margin-right: 6px;
}

input.form-check-input+label.filter1-label {
    font-size: 15px;
    line-height: 21px;
}

.filter1-label {
    color: #222;
    font-size: 14px;
    line-height: 21px;
    font-family: "NotoSansKR-Medium";
    margin-right: 6px;
    cursor: pointer;
}

input.form-control[type="text"],
input.form-control[type="text"]:focus,
input.form-control[type="password"],
input.form-control[type="password"]:focus,
input.form-control[type="datetime-local"],
input.form-control[type="datetime-local"]:focus,
input.form-control[type="date"],
input.form-control[type="date"]:focus,
input.form-control[type="time"],
input.form-control[type="time"]:focus,
input.form-control[type="month"],
input.form-control[type="month"]:focus,
textarea.form-control,
textarea.form-control:focus {
    color: #222;
    font-size: 14px;
    line-height: 21px;
    font-family: "NotoSansKR-Regular";
    padding: 0 7px;
    border-radius: unset;
    border-color: #c5c5c5;
    box-shadow: none;
    resize: none;
}

.form-control.filter-input,
.form-control.filter-input:focus {
    color: #222;
    font-size: 14px;
    line-height: 21px;
    font-family: "NotoSansKR-Medium";
    padding: 0 7px;
    border-radius: unset;
    border-color: #707070;
    box-shadow: none;
}

.form-control.filter1-input1 {
    width: 150px;
    height: 25px;
}

.form-control.filter1-input2 {
    width: 150px;
    height: 25px;
}

select.filter1-select1 {
    width: 150px;
    height: 25px;
}

select.filter1-select1.lg {
    width: 165px;
}

.btn.btn-primary,
.btn.btn-primary:hover,
.btn.btn-primary:focus,
.btn.btn-primary:active {
    border-color: #0b4da2;
    border-radius: 3px;
    min-width: 70px;
    width: fit-content;
    height: 25px;
    padding: 0 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 15px;
    line-height: 21px;
    font-family: "NotoSansKR-Bold";
    background-color: #fff;
}

.btn.btn-outline-primary {
    border-color: #0b4da2 !important;
    border-radius: 3px;
    min-width: 70px;
    width: fit-content;
    height: 25px;
    padding: 0 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #0b4da2 !important;
    font-size: 15px;
    line-height: 21px;
    font-family: "NotoSansKR-Bold";
    background-color: #fff !important;
}

.btn.btn-outline-danger {
    border-color: #f7280a !important;
    border-radius: 3px;
    min-width: 70px;
    width: fit-content;
    height: 25px;
    padding: 0 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #f7280a !important;
    font-size: 15px;
    line-height: 21px;
    font-family: "NotoSansKR-Bold";
    background-color: #fff !important;
}

.btn-outline-primary.btn-filter-outline-primary,
.btn-outline-primary.btn-filter-outline-primary:hover,
.btn-outline-primary.btn-filter-outline-primary:focus,
.btn-outline-primary.btn-filter-outline-primary:active {
    border-color: #0b4da2;
    border-radius: 3px;
    min-width: 70px;
    width: fit-content;
    height: 25px;
    padding: 0 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #0b4da2;
    font-size: 15px;
    line-height: 21px;
    font-family: "NotoSansKR-Bold";
    margin-right: 8px;
    background-color: #fff;
}

.table.my-table1 tbody tr td .btn-outline-primary.btn-filter-outline-primary,
.table.my-table1 tbody tr td .btn-outline-primary.btn-filter-outline-primary:hover,
.table.my-table1 tbody tr td .btn-outline-primary.btn-filter-outline-primary:focus,
.table.my-table1 tbody tr td .btn-outline-primary.btn-filter-outline-primary:active {
    padding: 0;
}

div.tab-content {
    background-color: #fff;
    /* padding: 15px 20px; */
    /* padding: 15px 0; */
    padding: 0;
}

div.tab-content .tab-pane {}

div.tab-content .btn.btn-outline-primary,
div.tab-content .btn.btn-outline-danger {
    font-size: 15px;
    line-height: 21px;
}

.table-tree-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    /*margin-bottom:30px;*/
    /*min-width: 1280px;*/
}

.table-outer {
    width: 100%;
    /*margin-right: 20px;*/
    /*width:calc(100% - 319px);*/
    /*min-height: 280px;*/
    /* background-color: lightyellow; */
}

.tree-outer {
    width: 355px;
    margin-right: 15px;
    /*min-height: 280px;*/
    /* background-color: lightblue; */
}

.btn-primary,
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
    border-color: #2b2b91 !important;
    background-color: #0b4da2 !important;
    color: #fff;
    font-size: 15px;
    line-height: 21px;
    font-family: "NotoSansKR-Medium";
    display: flex !important;
    align-items: center;
    justify-content: center;
    border-radius: 3px !important;
    padding: 0 !important;
}

.btn-table-filter.btn-primary,
.btn-table-filter.btn-primary:hover,
.btn-table-filter.btn-primary:focus,
.btn-table-filter.btn-primary:active {
    /*width:70px;*/
    width: fit-content;
    padding: 0 10px !important;
    height: 25px;
    /*margin-left:20px;*/
    font-size: 15px;
    line-height: 21px;
    font-family: "NotoSansKR-Medium";
}

select.form-select,
select.form-select:hover,
select.form-select:focus,
select.form-select:active {
    color: #222;
    font-size: 14px !important;
    line-height: 21px !important;
    font-family: "NotoSansKR-Medium";
    border-color: #c5c5c5;
    border-radius: unset !important;
    padding: 0 5px !important;
    box-shadow: unset;
    background-image: url(../../public/images/select-arrow-dwn.png);
    background-repeat: no-repeat;
    background-size: auto;
    background-position-x: calc(100% - 10px);
}

.table-filter-select.form-select,
.table-filter-select.form-select:hover,
.table-filter-select.form-select:focus {
    width: 150px;
    /*height:30px;*/
    color: #222;
    font-size: 15px;
    line-height: 21px;
    font-family: "NotoSansKR-Medium";
    box-shadow: unset;
}

.table-count {
    /*color: #222;*/
    /*font-size:15px;*/
    /*line-height: 21px;*/
    /*font-family:"NotoSansKR-Bold";*/
    display: flex;
    align-items: center;
    height: 35px;
    color: #222;
    font-size: 15px;
    line-height: 21px;
    font-family: "NotoSansKR-Bold";
    padding: 0;
}

.table-count span {
    color: #0b4da2;
}

.tree-title {
    display: flex;
    align-items: center;
    height: 35px;
    color: #222;
    font-size: 15px;
    line-height: 21px;
    font-family: "NotoSansKR-Bold";
    padding: 0 0;
}

.tree-title span {
    color: #2c2cad;
}

.table.my-table {
    margin-top: 10px;
    margin-bottom: 0;
}

.table.my-table thead tr,
.table.my-table thead tr th,
.table.my-table tfoot tr td {
    height: 30px;
    padding: 0 3px;
    vertical-align: middle;
    border-color: #c5c5c5;
    text-align: center;
    z-index: 2;
}

.table.my-table thead tr th {
    padding: 0;
}

.table.my-table tbody tr,
.table.my-table tbody tr td {
    /*height: 30px;*/
    /*height: fit-content;*/
    /*min-height: 30px;*/
    height: 30px;
    min-height: fit-content;
    padding: 0 3px;
    vertical-align: middle;
    border-color: #c5c5c5;
    text-align: center;
}

.table.my-table tbody tr td input,
.table.my-table tbody tr td select {
    cursor: pointer;
}

.table.my-table tbody tr td textarea {
    width: 100%;
    /* height: 30px; */
    resize: none;
    border: 1px solid #c5c5c5;
    word-wrap: break-word;
}

.table.my-table tbody tr td textarea:disabled {
    height: fit-content;
    min-height: 20px;
    box-sizing: border-box;
    resize: none;
    width: 100%;
}

.table.my-table tfoot tr td {
    border-bottom: 1px solid #707070;
}

.table.my-table thead tr th,
.table.my-table tfoot tr td {
    text-align: center;
    color: #222;
    font-size: 14px;
    line-height: 20px;
    font-family: "NotoSansKR-Bold";
    background-color: #f7f9ff;
}

.table.my-table.f-smaller thead tr th,
.table.my-table.f-smaller tfoot tr td {
    font-size: 14px;
}

.table.my-table thead>tr:first-child {
    border-top: 2px solid #0b4da2;
}

.table.my-table tbody tr td {
    color: #222;
    font-size: 14px;
    line-height: 20px;
    font-family: "NotoSansKR-Regular";
    /*cursor: pointer;*/
    word-break: break-all;
}

.tree-container {
    width: 100%;
    height: 243px;
    border: 1px solid #707070;
    border-radius: 9px;
    padding: 7px 10px;
    background-color: #fff;
    /*overflow: hidden;*/
    margin-top: 12px;
}

.tree-card-heading {
    color: #222;
    font-size: 15px;
    line-height: 21px;
    font-family: "NotoSansKR-Medium";
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 10px;
}

.tabs-main-heading {
    color: #222;
    font-size: 20px;
    line-height: 29px;
    font-family: "NotoSansKR-Bold";
    margin-bottom: 10px;
    /*width: calc(100% - 320px);*/
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 2px;
}

.table-tabs-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    /*min-width: 1280px;*/
}

.tabs-wrapper {
    /* background-color: lightyellow; */
    /*width:calc(100% - 319px);*/
    width: 100%;
    /*min-width: 1560px;*/
    /*min-height: 280px;*/
}

.tabs-wrapper.w-side-menu {
    width: calc(100% - 436px);
    overflow: hidden;
}

.tabs-wrapper .customerMainWrap {
    display: flex;
    justify-content: space-between;
}

.side-menu {
    margin-left: 14px;
    width: 422px;
    min-width: 322px;
    /*min-height: 280px;*/
    /* background-color: lightblue; */
    /*padding-top:30px;*/
}

.side-menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
}

.side-menu>ul>li {
    width: 100%;
    background-color: #fff;
    border: 1px solid #c5c5c5;
    margin-bottom: 20px;
}

.side-menu>ul>li:last-child {
    margin: 0;
}

/*.sidebar-notifications > ul > li div{*/
/*    width:100%;*/
/*    height: 45px;*/
/*    display:flex;*/
/*    align-items: center;*/
/*    justify-content: space-between;*/
/*    text-decoration: none;*/
/*    color: #222;*/
/*    font-size:15px;*/
/*    line-height: 21px;*/
/*    font-family:"NotoSansKR-Bold";*/
/*}*/
/*.sidebar-notifications ul li a span.badge{*/
/*    width:25px;*/
/*    height:25px;*/
/*    display: inline-flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    border-color: #FF0801;*/
/*    background-color: #FF0A00;*/
/*    color: #fff;*/
/*    font-size:20px;*/
/*    line-height: 29px;*/
/*    font-family:"NotoSansKR-Bold";*/
/*    margin-right:20px;*/
/*}*/
.side-menu-wrap {
    width: 100%;
}

span.badge {
    width: 25px;
    height: 25px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-color: #ff0801;
    background-color: #ff0a00 !important;
    color: #fff;
    font-size: 14px;
    line-height: 20px;
    font-family: "NotoSansKR-Bold";
    margin-right: 20px;
}

.side-menu-title {
    height: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px 0 20px;
    cursor: pointer;
    font-family: "NotoSansKR-Medium";
}

.side-menu-title img {
    margin-left: 10px;
}

.side-menu-title img.active {
    transform: rotate(180deg);
}

.side-menu-content {
    width: 100%;
    /*height: 215px;*/
    border-top: 1px solid #707070;
    display: none;
    padding: 5px;
}

.side-menu-content.active {
    display: block;
    border-top: none;
}

.tabs-wrapper ul.nav-tabs {
    border-bottom: 2px solid #0B4DA2;
    min-width: 1226px;
    margin: 0;
    padding: 0;
}

.tabs-wrapper ul:first-child li.nav-item {
    margin-right: -1px;
}

.tabs-wrapper ul.tabs-rectangle:first-child li.nav-item button,
.tabs-wrapper ul.tabs-rectangle:first-child li.nav-item button:hover,
.tabs-wrapper ul.tabs-rectangle:first-child li.nav-item button:focus {
    width: 140px;
    height: 25px;
    margin: 0;
    color: #222;
    font-size: 15px;
    line-height: 21px;
    font-family: "NotoSansKR-Bold";
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 11px;
    padding-right: 8px;
    border: none;
}

.tabs-wrapper ul.tabs-rectangle:first-child li.nav-item button,
.tabs-wrapper ul.tabs-rectangle:first-child li.nav-item button:hover,
.tabs-wrapper ul.tabs-rectangle:first-child li.nav-item button.nav-link {
    border: none;
    background: url(../../public/images/tab-bg.png) no-repeat;
    background-size: cover;
    color: #222;
}

.tabs-wrapper ul.tabs-rectangle:first-child li.nav-item button.nav-link.active {
    background: url(../../public/images/tab-bg-active.png) no-repeat;
    color: #fff;
}

.tabs-wrapper ul.tabs-rectangle:first-child li button span.btn-tab-close {
    width: 24.04px;
    height: 24.04px;
    background: url(../../public/images/tab-close.png) no-repeat;
    background-position: center;
    cursor: pointer;
}

.tabs-wrapper ul.tabs-rectangle:first-child li button.nav-link.active span.btn-tab-close {
    background: url(../../public/images/tab-close-active.png) no-repeat;
    background-position: center;
}

.tab-content-container {
    /*width: 1900px;*/
    /* width: calc(100% - 20px); */
    /*height:fit-content;*/
    /*width:100%;*/
    /*width:calc(100% - 20px);*/
    background-color: #fff;
    padding: 10px 20px;
    /*min-width: 1000px;*/
    /* border-top: 2px solid #0b4da2; */
}

.tab2-content-container {
    padding: 15px 0 10px 0;
    background-color: #fff;
    /*width:100%;*/
    /* width: calc(100% - 20px);*/
    /* border-top: 2px solid #0b4da2; */
}

.tab-content .btn,
.tab-content .btn:hover,
.tab-content .btn:focus,
.tab-content .btn:active,
.tab-content .btn:visited {
    height: 25px;
}

.tab1-row1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.btn-outline-primary.tab1-row1-btn,
.btn-outline-danger.tab1-row1-btn {
    width: fit-content;
    min-width: 70px;
    height: 25px;
    margin-left: 8px;
}

.tab1-row1-count {
    color: #222;
    font-size: 15px;
    line-height: 21px;
    font-family: "NotoSansKR-Bold";
}

.tab1-row1-field1 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.tab1-row1-field1 label {
    color: #222;
    font-size: 15px;
    line-height: 21px;
    font-family: "NotoSansKR-Bold";
    margin-left: 37px;
    margin-right: 6px;
    margin-right: 8px;
}

.form-select.tab1-row1-form-select {
    width: 150px;
    height: 25px;
    margin-right: 8px;
}

.table.my-table1 {
    /*margin-top:10px;*/
    /*margin-bottom: 20px;*/
}

.table.my-table1 select:disabled {
    background-color: #fff;
}

.table.my-table1 tbody tr,
.table.my-table1 tbody tr td {
    height: 30px;
    padding: 0 3px;
    vertical-align: middle;
    border-color: #c5c5c5;
    color: #222;
    font-size: 15px;
    line-height: 21px;
    font-family: "NotoSansKR-Regular";
}

.table.my-table1 tbody tr th {
    text-align: center;
    color: #222;
    font-size: 14px;
    line-height: 20px;
    font-family: "NotoSansKR-Medium";
    background-color: #fafafa;
    padding: 0;
    vertical-align: middle;
    font-weight: 200;
}

.table.my-table1 tbody tr td select.form-select,
.table.my-table1 tbody tr td input[type="text"].form-control,
.table.my-table tbody tr td select.form-select {
    height: 25px;
}

.table.my-table1 thead tr {
    border-top: 2px solid #0b4da2;
}

.table.my-table1 tbody tr td input.form-control,
.table.my-table1 tbody tr td select.form-select,
.table.my-table1 tbody tr td textarea.form-control,
textarea.form-control {
    border-color: #c5c5c5 !important;
}

input.table-input {
    width: 100%;
    height: 25px;
    border-color: #707070;
}

input.table-input.table-input-inline,
select.table-select.table-select-inline {
    width: calc(50% - 1.5px) !important;
    margin: 0;
}

input.table-input.table-input-inline.w-20 {
    width: calc(20% - 1.5px) !important;
}

input.table-input.table-input-inline.w-80 {
    width: calc(80% - 1.5px) !important;
}

button.table-button {
    height: 31px;
}

.table-input.table-input-short {
    width: 234px;
    float: left;
}

.table-input.input-of-button {
    width: calc(100% - 73px);
    float: left;
}

.table-input.button-of-input {
    width: 70px;
}

.form-control.table-input:disabled {
    background-color: #fff;
    border: none;
}

.form-control.table-input.bordered:disabled {
    background-color: #fff;
    border: 1px solid #c5c5c5 !important;
}

input[type="number"].form-control.table-input {
    border-radius: 0;
}

table tr td.input-col-3 {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

table tr td.input-col-3 input[type="text"].form-control {
    width: calc(33% - 3px) !important;
}

table tr td.input-col-2 input[type="text"].form-control {
    width: calc(50% - 3px) !important;
    float: left;
}

table tr td.input-col-2 input[type="text"].form-control:last-child {
    float: right;
}

input.form-control.table1-email1 {
    width: 45.8%;
    float: left;
}

select.form-select.table1-email2 {
    width: 48.75%;
    float: right;
    height: 31px;
}

input.form-control.table1-email2 {
    width: 48.75%;
    float: right;
    height: 25px;
}

input.form-control.table1-email1.small {
    width: 43%;
}

select.form-select.table1-email2.small {
    width: 43%;
}

span.txt_at {
    width: 5.45%;
    height: 25px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #222;
    font-size: 15px;
    line-height: 21px;
    font-family: "NotoSansKR-Bold";
    padding: 0 3px;
}

span.txt_at.small {
    width: 13%;
}

.table.my-table1 tbody tr td select.form-select.left {
    width: 48.75%;
    float: left;
}

.table.my-table1 tbody tr td input.form-control[type="text"].right {
    width: 48.75%;
    float: right;
}

.table-tabs-sub {
    color: #222;
    font-size: 15px;
    line-height: 20px;
    font-family: "NotoSansKR-Bold";
    margin-bottom: 10px;
}

.table-tabs-sub-golfManage-subtitle {
    color: #222;
    font-size: 15px;
    line-height: 20px;
    font-family: "NotoSansKR-Bold";
    margin-bottom: 10px;
}

div.tab2-content-container div.table-tabs-sub {
    font-size: 15px;
    line-height: 21px;
}

.table-tabs-sub+img {
    cursor: pointer;
}

.table-tabs-sub+img.active {
    transform: rotate(180deg);
}

.table-tabs-sub .small {
    font-size: 13px;
}

.table-tabs-sub+textarea {
    width: 100%;
    height: 200px;
    resize: none;
}

.table-tabs-subsub {
    color: #222;
    line-height: 22px;
    font-family: "NotoSansKR-Bold";
    font-size: 16px;
}

.table-tabs-caption {
    font-size: 12px;
    margin-left: 10px;
}

.btn-tbl-add {
    width: 70px;
}

ul.tab-blocks.nav-tabs {
    align-items: flex-end;
    width: 100%;
    /* width: calc(100% - 20px);*/
    border-bottom: 2px solid #0B4DA2;
    margin-top: 14px;
}

ul.tab-blocks.nav-tabs li.nav-item {
    height: fit-content;
    margin-left: -1px;
}

ul.tab-blocks.nav-tabs li.nav-item button,
ul.tab-blocks.nav-tabs li.nav-item button:hover,
ul.tab-blocks.nav-tabs li.nav-item button:focus {
    width: fit-content;
    min-width: 90px;
    min-height: 30px;
    margin: 0;
    color: #222;
    font-size: 15px;
    line-height: 21px;
    font-family: "NotoSansKR-Bold";
    padding: 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-color: #c5c5c5;
    border-radius: unset;
    border-left-width: 1px;
    background-color: #fff;
    /* border-bottom-width: 0; */
    margin-bottom: -1px;
}

ul.tab-blocks.nav-tabs li.nav-item:first-child button {
    border-left-width: 1px;
}

ul.tab-blocks.nav-tabs li.nav-item button.nav-link.active {
    background-color: #0b4da2;
    color: #fff;
}

ul.my-treeview,
ul.my-treeview ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

ul.my-treeview {
    /*margin-top:10px;*/
    margin-bottom: 20px;
}

ul.my-treeview ul {
    margin-left: 10px;
}

ul.my-treeview>li:first-child>div {
    color: #222;
    font-size: 15px;
    line-height: 21px;
    font-family: "NotoSansKR-Medium";
}

ul.my-treeview li {
    margin: 0;
    padding: 0 7px;
    line-height: 20px;
    border-left: 1px dotted rgb(100, 100, 100);
    cursor: pointer;
}

ul.my-treeview>li>ul li {
    color: #222;
    font-size: 15px;
    line-height: 21px;
    font-family: "NotoSansKR-Regular";
}

ul.my-treeview ul>li:hover {
    background-color: #f7f9ff;
}

ul.my-treeview ul>li:hover>ul>li {
    background-color: #fff;
}

ul.my-treeview ul>li>ul>li:hover {
    background-color: #f7f9ff;
}


ul.my-treeview li:last-child {
    border-left: none;
}

/*ul.my-treeview li:before {*/
/*  position:relative;*/
/*  top:-0.3em;*/
/*  height:1em;*/
/*  width:12px;*/
/*  color:white;*/
/*  border-bottom:1px dotted rgb(100,100,100);*/
/*  content:"";*/
/*  display:inline-block;*/
/*  left:-7px;*/
/*}*/
ul.my-treeview li:last-child:before {
    border-left: 1px dotted rgb(100, 100, 100);
}

.my-treeview-wrapper {
    width: 100%;
    height: inherit;
    /*max-height: calc(100% - 40px);*/
    height: 100%;
    overflow-y: auto;
}

.two-col-outer {
    width: 100%;
    border: 0 solid red;
    height: fit-content;
    margin-bottom: 14px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.col-left {
    flex: 1 1 60%;
    height: fit-content;
    max-width: 961.8px;
}

.col-right {
    flex: 1 1 40%;
    height: fit-content;
    padding-left: 14px;
    max-width: calc(100% - 961.8px);
}

.tabs-sidebar-wrapper {
    /* padding-left: 10px; */
}

.tabs-sidebar-wrapper .tab-blocks {
    margin: 0 !important;
}

.iframe-content {
    width: 100%;
    outline: none;
    border: none;
    z-index: 0;
}

.filter-perpage {
    width: 150px;
    max-width: 150px;
}

.card {
    border: unset !important;
    border-radius: 9px !important;
}

.card-main {
    margin-top: 14px;
    border: unset !important;
    border-radius: 9px !important;
    overflow: hidden;
}

.card.card-sub {
    border: unset !important;
    border-radius: 9px !important;
    margin-top: 14px;
    overflow: hidden;
}

.card-sub .card-body {
    padding: 15px 20px;
}

.card-sub .card-body .tab-content-container,
.card-sub .card-body .tab2-content-container {
    padding-left: 0;
    padding-right: 0;
}

div.card-header {
    height: 50px;
    background-color: #fff;
    display: flex;
    align-items: center;
}

div.card-footer {
    height: 50px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

div.footer-txt1 {
    color: #222;
    font-size: 15px;
    line-height: 21px;
    font-family: "NotoSansKR-Bold";
}

div.footer-txt2 {
    color: #222;
    font-size: 15px;
    line-height: 21px;
    font-family: "NotoSansKR-Regular";
}

.card-main .card-header {
    height: 50px;
    background-color: #fff;
}

.card-title {
    color: #222;
    font-size: 20px;
    line-height: 20px;
    font-family: "NotoSansKR-Bold";
    margin-bottom: 0;
}

.card-title small {
    font-size: 12px;
}

.card-main .card-header .card-title {
    color: #222;
    font-size: 20px;
    line-height: 20px;
    font-family: "NotoSansKR-Bold";
    margin-bottom: 0;
}

label {
    color: #222;
    font-size: 15px;
    line-height: 21px;
    font-family: "NotoSansKR-Regular";
}

label.form-check-label {
    color: #222;
    font-size: 15px;
    line-height: 21px;
    font-family: "NotoSansKR-Bold";
    cursor: pointer;
}

label.form-check-label.nowrap {
    font-family: "NotoSansKR-Regular";
}

.link-expand,
.link-expand:hover,
.link-expand:focus,
.link-expand:active,
.link-expand:visited {
    color: #222;
    font-size: 15px;
    line-height: 21px;
    font-family: "NotoSansKR-Bold";
    text-decoration: none;
}

.table-sticky {
    width: 100%;
    border-top: 2px solid #0b4da2;
    overflow: auto;
    border-bottom: 1px solid #c5c5c5;
}

.table-sticky table {
    position: relative;
}

.table-sticky table thead th {
    position: sticky;
    top: 0;
    background: #ffffff;
}

.table-sticky table thead th::after {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border: 1px solid;
    border-color: inherit;
    pointer-events: none;
}

/* .table-sticky1 table thead tr>th:first-child, */
.table-sticky1 table tbody tr>td:first-child {
    position: -webkit-sticky;
    position: sticky;
    left: 0 !important;
    z-index: 1;
}

.table-sticky table thead tr:nth-child(2) th {
    top: 30px;
}

.table-sticky table thead tr:nth-child(3) th {
    top: 60px;
}

.table-sticky table thead th.border-bottom-th {
    border-bottom: 1px solid #c5c5c5;
}

.table-sticky table thead th.border-bottom-th:not(:first-child) {
    border-left: 1px solid #c5c5c5;
}

.table-sticky .table.my-table thead tr {
    border: unset;
}

.table-sticky .table.my-table thead tr:not(:first-child) th {
    border-top: 1px solid #c5c5c5;
}

.table-sticky .table.my-table tbody tr:last-child {
    border-bottom: unset;
}

.txt_daterange {
    color: #222;
    font-size: 15px;
    line-height: 21px;
    font-family: "NotoSansKR-Bold";
    margin: 0 10px;
}

.stat-item {
    display: flex;
    align-items: flex-start;
    padding: 0 20px;
    color: #222;
    font-size: 15px;
    line-height: 21px;
    font-family: "NotoSansKR-Bold";
}

.stat-item .stat-num {
    color: #2b2b91;
}

/* ::-webkit-scrollbar {
    width: 10px;
    height:10px;

}
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.8);
    border-radius: 6px;
    background-color: grey;
}
::-webkit-scrollbar-thumb {
    border-radius: 6px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.9);
    background-color: grey;
} */

.quot_heading {
    color: #222;
    font-size: 28px;
    line-height: 34px;
    font-family: "NotoSansKR-Bold";
    text-align: center;
    margin-bottom: 28px;
}

.quot_heading-2 {
    color: #222;
    font-size: 24px;
    line-height: 30px;
    font-family: "NotoSansKR-Bold";
    text-align: center;
    margin: 35px auto 25px auto;
}

.quot_heading-2.sub {
    font-size: 18px;
}

.quot_heading-2.sub_sub {
    font-size: 28px;
}

.chk-sm {
    color: #222;
    font-size: 15px;
    line-height: 21px;
    font-family: "NotoSansKR-Regular";
}

.req-doclist {
    padding: 0 0 0 20px;
    margin: 0;
}

.req-doclist li {
    color: #222;
    font-size: 15px;
    line-height: 21px;
    font-family: "NotoSansKR-Bold";
    margin-bottom: 15px;
}

.req-doclist li:last-child {
    margin: 0;
}

.otherinfo {
    color: #222;
    font-size: 15px;
    line-height: 23px;
    font-family: "NotoSansKR-Regular";
    margin: 0;
}

.quot-row {
    margin-bottom: 15px;
}

.quot-label {
    width: 100px;
    color: #222;
    font-size: 15px;
    line-height: 21px;
    font-family: "NotoSansKR-Bold";
}

.quot-value {
    color: #222;
    font-size: 15px;
    line-height: 21px;
    font-family: "NotoSansKR-Regular";
}

.p_quot,
.p_modal {
    color: #222;
    font-size: 15px;
    line-height: 21px;
    font-family: "NotoSansKR-Regular";
}

.list_pts {
    list-style: circle;
    margin: 0;
    padding: 0 0 0 20px;
}

.list_pts li {
    color: #222;
    font-size: 15px;
    line-height: 21px;
    font-family: "NotoSansKR-Regular";
}

.list_pts span {
    float: right;
}

.quot-co-name {
    color: #222;
    font-size: 15px;
    line-height: 21px;
    font-family: "NotoSansKR-Bold";
}

.quot-co-addr {
    color: #222;
    font-size: 15px;
    line-height: 21px;
    font-family: "NotoSansKR-Regular";
}

.quot-row-sm {
    margin-bottom: 8px;
}

.quot-label-sm {
    width: 70px;
    color: #222;
    font-size: 15px;
    line-height: 21px;
    font-family: "NotoSansKR-Bold";
}

.quot-value-sm {
    color: #222;
    font-size: 15px;
    line-height: 21px;
    font-family: "NotoSansKR-Regular";
}

.quot-txt1,
.quot-txt2,
.quot-txt3 {
    color: #222;
    font-size: 15px;
    line-height: 21px;
    font-family: "NotoSansKR-Regular";
    text-align: right;
    margin: 12px 0;
}

.quot-separator {
    border-top: 1px solid #ccc;
    margin: 5px 0;
    width: 100%;
    display: block;
}

.db-status-container {
    padding: 0 20px;
}

.db-status-title {
    color: #222;
    font-size: 20px;
    /* line-height: 20px; */
    font-family: "NotoSansKR-Bold";
    margin-bottom: 10px;
}

.db-status-filter {
    width: 100%;
    /* min-height: 51px; */
    height: -moz-fit-content;
    height: fit-content;
    background: #fff;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 10px 0;
}

.db-status-filter-title {
    color: #222;
    font-size: 15px;
    line-height: 21px;
    font-family: "NotoSansKR-Medium";
    margin-right: 6px;
    cursor: pointer;
    padding-left: 20px;
}

.db-status-filter-select {
    margin-right: 20px;
}

.customer-info-change-container {
    padding: 0 20px;
    min-width: 1330px;
}

.customer-info-change-title {
    color: #222;
    font-size: 20px;
    /* line-height: 20px; */
    font-family: "NotoSansKR-Bold";
    margin-bottom: 10px;
}

.customer-info-change-last-modify {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 6px;
}

.customer-info-change-modify-date {
    color: #0b4da2;
}

.customer-info-change-table-contianer {
    display: flex;
    justify-content: space-between;
}

.customer-info-change-inner-table1 {
    width: 78%;
}

.customer-info-change-inner-table2 {
    width: 20%;
}

.customer-info-change-table tbody {
    display: block;
    max-height: 1000px;
    overflow-y: auto;
}

.customer-info-change-table thead,
.customer-info-change-table tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.customer-info-change-table thead {
    width: calc(100% - 1.1em);
}

.customer-info-change-table-key {
    width: 20%;
}

.customer-info-change-table-name {
    width: 10%;
}

.customer-info-change-table-key-inner {
    position: relative;
}

.customer-info-change-table-select {
    position: absolute;
    left: 5%;
}

.customer-info-change-table-active {
    background-color: #0b4da2 !important;
    color: #ffffff !important;
}

.customer-info-change-tab {
    display: flex;
}

.customer-info-change-tab-item {
    border: 1px solid #222;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10%;
    background-color: #fff;
    margin-right: 10px;
    color: #222;
    font-size: 16px;
    /* line-height: 20px; */
    font-family: "NotoSansKR-Bold";
    border-radius: 4px;
}

.customer-info-change-tab-active {
    background-color: #0b4da2;
    border: none;
    color: #ffffff;
}

.new-db-status-container {
    padding: 0 20px;
    min-width: 1330px;
}

.new-db-status-title {
    color: #222;
    font-size: 20px;
    /* line-height: 20px; */
    font-family: "NotoSansKR-Bold";
    margin-bottom: 10px;
}

.new-db-btn-container {
    display: flex;
    justify-content: flex-end;
}

.post-page {
    padding: 0 20px;
    min-width: 1330px;
}

.post-page-title {
    color: #222;
    font-size: 20px;
    /* line-height: 20px; */
    font-family: "NotoSansKR-Bold";
    margin-bottom: 10px;
}

.post-pate-flex-container {
    display: flex;
    justify-content: space-between;
}

.post-page-flex-box1 {
    width: 38%;
}

.post-page-flex-box2 {
    width: 61%;
}

.post-page-box-header {
    display: flex;
    justify-content: space-between;
}

.post-delete-btn {
    margin-right: 0px !important;
}

.post-address-select {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
}

.post-note-span {
    color: #0b4da2;
    font-size: 15px;
    font-family: NotoSansKR-Medium;
}

.post-print-container {
    display: flex;
    justify-content: flex-end;
}

.post-work-select {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.post-work-select-box {
    width: 60% !important;
}

.post-select-label {
    color: #222;
    font-size: 16px;
    /* line-height: 20px; */
    font-family: "NotoSansKR-Bold";
    margin-bottom: 10px;
}

.post-setting-container {
    display: flex;
    justify-content: flex-end;
}

.post-setting-select {
    width: 40%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.post-work-setting-box {
    width: 40% !important;
}

.post-work-label {
    color: #222;
    font-size: 16px;
    /* line-height: 20px; */
    font-family: "NotoSansKR-Bold";
}

.post-table-header {
    display: flex;
    align-items: center;
    margin: 10px 0px;
    justify-content: space-between;
}

.post-page-table-btn {
    display: flex;
    align-items: center;
}

.post-page-table-title {
    color: #222;
    font-size: 20px;
    /* line-height: 20px; */
    font-family: "NotoSansKR-Bold";
}

.post-manage-btn {
    margin-right: 0px !important;
    margin-left: 6px;
}

.post-massage-table-input {
    width: 100%;
}

.post-account-history-print {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.post-account-history-print-btn {
    color: #222;
    font-size: 15px;
    /* line-height: 20px; */
    font-family: "NotoSansKR-Bold";
    border: 1px solid #222;
    width: 7%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0px;
    cursor: pointer;
    border-radius: 4px;
    margin-bottom: 10px;
}

.post-account-history-table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.post-customer-table-header {
    display: flex;
    align-items: center;
}

.post-note-span2 {
    color: #0b4da2;
    font-size: 15px;
    font-family: NotoSansKR-Medium;
    padding-left: 10px;
}

.post-management-search-btn {
    color: #222;
    font-size: 15px;
    /* line-height: 20px; */
    font-family: "NotoSansKR-Bold";
    border: 1px solid #222;
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0px;
    cursor: pointer;
    border-radius: 4px;
    margin-bottom: 10px;
}

.work-page {
    padding: 0 20px;
    min-width: 1330px;
}

.work-tax-header {
    display: flex;
    justify-content: space-between;
}

.work-page-title {
    color: #222;
    font-size: 20px;
    /* line-height: 20px; */
    font-family: "NotoSansKR-Bold";
    margin-bottom: 10px;
}

.work-tax-btn-container {
    display: flex;
    width: 18%;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 10px;
}

.work-tax-btn {
    color: #222;
    font-size: 15px;
    /* line-height: 20px; */
    font-family: "NotoSansKR-Bold";
    border: 1px solid #222;
    width: 44%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0px;
    cursor: pointer;
    border-radius: 4px;
    margin-bottom: 10px;
    margin-left: 8px;
    height: 30px;
}

.work-tax-history {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.work-tax-table-flex {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.work-tax-disable-input {
    color: #bbbbbb;
}

.work-tax-table-unit {
    color: #222;
    font-size: 15px;
    line-height: 21px;
    font-family: "NotoSansKR-Bold";
}

.work-tax-table-body tr td:first-child {
    text-align: center;
    color: #222;
    font-size: 15px;
    line-height: 21px;
    font-family: "NotoSansKR-Bold" !important;
    background-color: #e4e4e4;
}

.work_tax_submit_container {
    background: #fff;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 190px;
}

.work_tax_total_box {
    width: 49%;
    background-color: #fff;
    display: flex;
    height: 280px;
    flex-direction: column;
    padding: 18px 24px;
}

.work_tax_total_box_title {
    color: #222;
    font-size: 18px;
    line-height: 21px;
    font-family: "NotoSansKR-Medium";
    padding: 0;
    vertical-align: middle;
}

.work_tax_total_box_list_container {
    padding: 0 16px;
    margin-top: 15px;
}

.work_tax_total_box_list {
    display: flex;
    justify-content: space-between;
    color: #222;
    font-size: 15px;
    line-height: 21px;
    font-family: "NotoSansKR-Medium";
    padding: 0;
    vertical-align: middle;
    margin-bottom: 8px;
}

.work_tax_total_box_list_key {
    color: #222;
    font-size: 15px;
    line-height: 21px;
    font-family: "NotoSansKR-Medium";
    padding: 0;
    vertical-align: middle;
}

.work_tax_total_box_list .unit {
    margin-left: 5px;
}

.work_tax_total_box_list_value {
    width: 60%;
    display: flex;
    justify-content: flex-end;
}

.work_tax_total_box_list_value input {
    width: 80%;
}

.work_tax_note_sapn {
    color: #ff0000;
    font-family: "NotoSansKR-Bold";
    font-size: 12px;
    margin-top: 5px;
}

.work_tax_print_list {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    height: 35px;
}

.work_tax_print_box {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
}

.work_tax_print_btn {
    border: 1px solid #707070;
    color: #222;
    font-size: 15px;
    line-height: 21px;
    font-family: "NotoSansKR-Medium";
    padding: 0;
    vertical-align: middle;
    width: 48%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.work_tax_print_date {
    border: 1px solid #707070;
    color: #222;
    font-size: 15px;
    line-height: 21px;
    font-family: "NotoSansKR-Medium";
    padding: 0;
    vertical-align: middle;
    width: 48%;
    display: flex;
    align-items: center;
}

.work_tax_print_date .date {
    border-right: 1px solid #707070;
    height: 100%;
    display: flex;
    align-items: center;
    width: 40%;
    justify-content: center;
    background-color: #e4e4e4;
}

.work_tax_submit_txt {
    text-align: center;
    color: #222;
    font-size: 15px;
    line-height: 21px;
    font-family: "NotoSansKR-Medium";
    background-color: #fafafa;
    padding: 0;
    vertical-align: middle;
}

.work_checkbox_box {
    display: flex;
}

.work_checkbox_container {
    display: flex;
}

.table-stat {
    font-size: 15px;
    line-height: 21px;
    font-family: "NotoSansKR-Bold";
    margin: 0 18px;
}

.table-stat-count {
    font-size: 15px;
    line-height: 21px;
    font-family: "NotoSansKR-Regular";
    background-color: #fafafa;
}

.person-master-post-box {
    display: flex;
    align-items: center;
}

.person-master-post-input {
    width: 25% !important;
}

.person-master-post-detail {
    width: calc(75% - 76px) !important;
    margin-left: 0;
}

/* input type password */
/*input.form-control[type="password"],*/
/*input.form-control[type="password"]:focus,*/
textarea.form-control,
textarea.form-control:focus {
    color: #222;
    font-size: 15px;
    line-height: 21px;
    font-family: "NotoSansKR-Medium";
    padding: 0 7px;
    border-radius: unset;
    border-color: #707070;
    box-shadow: none;
    resize: none;
}

div.card.card-employeeinfo {
    width: 100% !important;
}

.list-employeeinfo .list-group-item {
    color: #222;
    font-size: 15px;
    line-height: 21px;
    font-family: "NotoSansKR-Regular";
}

.list-employeeinfo .list-group-item:last-child {
    padding-bottom: 0;
}

.expulsion-data {
    text-decoration: line-through;
    color: red !important;
}

.person-master-print-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.print-bg {
    background-color: #e4e4e4;
}

.print-bold {
    font-family: "NotoSansKR-Medium";
}

.print-header-name-space {
    width: 35%;
    display: flex;
    justify-content: center;
}

.person-master-print-main {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    display: flex;
}

.person-master-print-main img {
    width: 100px;
    height: 100px;
}

.person-master-print-main-section {
    border: 1px solid red;
    width: calc(100% - 100px);
    display: flex;
    flex-direction: column;
}

.person-master-print-row {
    display: flex;
    border: 1px solid blue;
}

.text-help {
    font-size: 15px;
    line-height: 21px;
    font-family: "NotoSansKR-Regular";
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.radio-image {
    width: fit-content;
    margin: 0.25em auto 0 auto !important;
}

.modal-dialog .col-form-label {
    padding-top: 0;
    padding-bottom: 15px;
    color: #222;
    font-size: 15px;
    line-height: 21px;
    font-family: "NotoSansKR-Regular";
}

.modal-title {
    color: #222;
    font-size: 18px;
    line-height: 24px;
    font-family: "NotoSansKR-Bold";
}

.modal-box1 {
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.modal-box1 p {
    margin-bottom: 40px;
    color: #222;
    font-size: 18px;
    line-height: 21px;
    font-family: "NotoSansKR-Regular";
}

.btn-edit-popup {
    position: absolute;
    right: 30px;
    top: 100px;
    z-index: 10;
}

table tbody tr td {
    position: relative;
}

.btn-edit-popup.td {
    right: 10px;
    top: 10px;
}

table tbody tr td.v-align-top {
    vertical-align: top !important;
}

tr.bg-orange td {
    background-color: #fbe5d6;
}

tr td.td_pink {
    background-color: #FFAEC9 !important;
}

tr.bg-blue td {
    background-color: #4472c4 !important;
    color: #fff !important;
}

tr.row-bg-acquTax-color1 td {
    background-color: #deffde !important;
    color: #000 !important;
}

tr.bg-darkgrey td,
tr.bg-darkgrey th {
    background-color: #ddd !important;
}

tr.bg-grey td,
tr.bg-grey th,
tr td.bg-grey {
    background-color: #eee !important;
}

tr.bg-lightgrey td {
    background-color: #fafafa !important;
}

tr.bg-lightblue td {
    background-color: lightsteelblue;
}

tr.bg-lightblue td input {
    background-color: lightsteelblue;
}

tr.bg-lightgreen td {
    background-color: lightgreen;
}

tr.bg-lightyellow td {
    background-color: lightgoldenrodyellow;
}

tr.bg-red td {
    background-color: #ffdddd;
    text-decoration: line-through;
}

tr.bg-only-red td {
    background-color: #ffdddd;
}

tr.bg-only-red2 td {
    background-color: #ff0000;
    color: white !important;
    font-weight: bold;
}

tr.bg-only-red td input {
    border: 1px solid #ffdddd;
    background-color: #ffdddd;
}

tr.bg-only-red2 td input {
    border: 1px solid #ff0000;
    background-color: #ff0000;
    color: white !important;
    font-weight: bold;
}

tr.bg-only-red td select {
    border: 1px solid #ffdddd;
    background-color: #ffdddd;
}




tr.deleted td {
    text-decoration: line-through;
}

tr.bg-red td input {
    border: 1px solid #ffdddd;
    background-color: #ffdddd;
}

tr.bg-red td select {
    border: 1px solid #ffdddd;
    background-color: #ffdddd;
}

tr.bg-green td {
    background-color: #00ff00;
}

.form-label {
    color: #222;
    font-size: 15px;
    line-height: 21px;
    font-family: "NotoSansKR-Regular";
}

/*table tbody tr td.bg-red {*/
/*    background-color: #ff0000;*/
/*}*/

.pdf_wrapper {
    width: 100%;
    min-height: 800px;
    border: 1px solid lightgray;
}

.capital_h1 {
    color: #222;
    font-size: 18px;
    line-height: 21px;
    font-family: "NotoSansKR-Bold";
    text-align: center;
}

.txt_highlight_1 {
    background-color: yellow;
}

.txt_highlight_2 {
    color: #fff;
    background-color: blue;
}

.memberimg_holder {
    border: 1px solid #000;
    width: 100%;
    height: 180px;
}

.h1_tbl_depositwithdraw {
    color: #222;
    font-size: 24px;
    line-height: 21px;
    font-family: "NotoSansKR-Bold";
    text-align: left;
    margin: 20px 0;
}

.pl-305px {
    padding-left: 305px;
    margin: 2px 0;
}

.pl-12p {
    padding-left: 12%;
}

.person-employ-status-search-result {
    width: 69%;
}

.person-employ-status-report {
    width: 30.5%;
}

.person-employ-status-report .w-100 {
    width: 100%;
}

.tax-initial-submit-cursor {
    cursor: pointer;
}

.modal-container {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: rgb(0, 0, 0, 0.4);
}

.modal-content {
    /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1420px;
    background: #ffffff;
    border-radius: 8px;
    padding: 28px 28px;
    overflow-y: auto;
    border: 1px solid red;

    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background: #ced4da;
        border-radius: 10px;
    } */
}

.modal-xxl .modal-dialog.modal-xl {
    max-width: 1870px;
}


.modal-xxl .modal-content {
    width: fit-content;
}

div.col-name {
    width: 45.25%;
}

input.form-control.input-name-modal {
    width: 48%;
}

div.col-buttons {
    width: 12.3%;
}

div.col2-input-btn,
input.col2-input-btn {
    width: calc(100% - 73px);
    margin-right: 3px;
}

input.col2-input-select {
    width: calc(100% - 203px);
}

input.col2-input-arrowbtn {
    width: calc(100% - 28px);
}

input.col2-dash,
select.select-col2 {
    width: 49%;
}

input.col3-dash,
select.select-col3 {
    width: 32.5%;
}

.flex-center-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flex-center-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.flex-center-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.emp-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pe-20 {
    padding-right: 20px;
}

.px-20,
.px-20px {
    padding-left: 20px !important;
    padding-right: 20px !important;
}

table tbody tr td.cell-bg-pink,
table tbody tr th.cell-bg-pink,
table thead tr th.cell-bg-pink,
table thead tr td.cell-bg-pink {
    background-color: #fbe5d6 !important;
}

table tbody tr td.cell-bg-blue,
table tbody tr th.cell-bg-blue,
table thead tr td.cell-bg-blue,
table thead tr th.cell-bg-blue {
    background-color: #deebf7 !important;
}

.images-container-scroller {
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 710px;
}

.nowrap {
    white-space: nowrap;
}

input.form-control.input-address1 {
    width: 35.5%;
}

.input-address2 {
    width: 39.63%;
}

.input-address3 {
    width: 39.63%;
}

.txt_dash {
    margin: 0 3px;
}

.tab-close {
    position: relative;
    cursor: pointer;
    width: 20px;
    height: 20px;
}

.tab-close::before {
    content: "";
    width: 1px;
    height: 80%;
    background-color: #c5c5c5;
    transform: rotate(45deg);
    position: absolute;
    top: 20%;
    right: 0;

}

.tab-close::after {
    content: "";
    width: 1px;
    height: 80%;
    background-color: #c5c5c5;
    transform: rotate(135deg);
    position: absolute;
    top: 20%;
    right: 0;
}

.select-virtual {
    margin: 0 !important;
    background: unset;
    padding: 0 !important;
    border: unset !important;
}

.select-virtual>span+div {
    height: 25px;
    min-height: 25px !important;
    align-items: flex-start;
    padding: 0;
    border-radius: unset;
}

.select-virtual>span+div>div:first-child div {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
}

.select-virtual>div:last-child {
    white-space: nowrap;
}

.select-virtual>span+div>div:last-child div {
    padding-top: 0;
    padding-bottom: 0;
}

.select-virtual>div:first-child>div {
    height: 25px;
    min-height: 25px !important;
    align-items: flex-start;
    padding: 0;
}

.text-h1 {
    padding-left: 45px;
}

.text-h2 {
    padding-left: 288px;
}

.modal-dialog-centered {
    justify-content: center;
}

.modal-scroll .modal-dialog.modal-xl {
    max-width: 1870px;
}

.link-expand img.active {
    transform: rotate(180deg);
}

.custom-table {
    table-layout: fixed;
}


.vw-60 {
    width: 60vw !important;
}

table.table.table-bordered tbody tr td label.form-check-label {
    font-size: 15px;
    line-height: 21px;
    font-family: "NotoSansKR-Regular";
    text-wrap: nowrap;
}

table.table.table-bordered tbody tr td div.form-check-inline {
    margin-right: 0;
}

.report-output-color-uncheck {
    background-color: rgb(255, 255, 187) !important;
}

.report-output-color-check {
    background-color: rgb(211, 248, 252) !important;

}

.heading1 {
    text-align: center;
    font-size: 28px;
    font-family: "NotoSansKR-Bold";
    margin: 15px 0 0;
}

.click_box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
    width: 350px;
    height: 120px;
}


.individualPerformanceClose_tr_disabled td {
    background-color: lightgray !important;
}

.account_disabled td {
    background-color: lightgray !important;

}

.td_middle_for_personMaster {
    background-color: #fafafa !important;

}

tr.bg-red-col td {
    background-color: #E8F6F0 !important;
}

.color-red-col {
    color: #fd7373 !important;
}

.calendar_cell {
    height: 135px !important;
    display: flex;
    flex-direction: column;
}

.calendar_header {
    background-color: #f7f9ff !important;
    border-top: 2px solid #0b4da2;
    font-weight: bold;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.calenar_red {
    color: #fd7373;
}

.calenar_blue {
    color: #0b4da2;
}

.calendar_tody {
    background-color: #fbe5d6;
}

.calendar_focus {
    background-color: #E8F6F0 !important;
}

.calendar_disabled {
    color: #a9a9a9;
    /* background-color: #fafafa; */
}

.calendar_text_container {
    height: 110px;
    overflow: scroll;
}

.calendar_text_container::-webkit-scrollbar {
    display: none;
}

.calendar_text_box {
    font-size: 12px;
    display: flex;
    flex-direction: column;
}

tr.deleted td {
    background-color: orange;
}

tr.late_color td {
    color: #fd7373 !important;
}

tr.time_center td input.time {
    text-align: center;
}

.tax-reissue-download-address::-webkit-scrollbar {
    display: none;
}

.scrollable-table th:nth-child(1),
.scrollable-table td:nth-child(1) {
    position: sticky;
    left: -1px;
    background: #fff;
    z-index: 1;
}

.scrollable-table th:nth-child(2),
.scrollable-table td:nth-child(2) {
    position: sticky;
    left: 43px;
    background: #fff;
    z-index: 1;
}

.scrollable-table th:nth-child(3),
.scrollable-table td:nth-child(3) {
    position: sticky;
    left: 87px;
    background: #fff;
    z-index: 1;
}

.scrollable-table th:nth-child(4),
.scrollable-table td:nth-child(4) {
    position: sticky;
    left: 193px;
    background: #fff;
    z-index: 1;
}

.scrollable-table th:nth-child(5),
.scrollable-table td:nth-child(5) {
    position: sticky;
    left: 282px;
    background: #fff;
    z-index: 1;
}